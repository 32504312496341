import Cookies from 'js-cookie';
import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router';

// import { clearPending } from '../axios/pendingRequests';
import { setLocalStorage } from '../helper/LocalStorage';
import Layout from '../layout/Layout';
import UserManagement from '../pages/users/UserManagement';

function EnableDebug() {
  React.useEffect(() => {
    // eslint-disable-next-line no-console
    console.log('%c DEBUG ENABLED ', 'background: #EDF7EE; color: #2E6930');
    setLocalStorage('debug-prism', true);
  }, []);

  return (
    <Navigate
      to="/"
    />
  );
}

function DisableDebug() {
  React.useEffect(() => {
    // eslint-disable-next-line no-console
    console.log('%c DEBUG DISABLED ', 'background: #FAE9E7; color: #7A160B');
    setLocalStorage('debug-prism', false);
  }, []);

  return (
    <Navigate
      to="/"
    />
  );
}

/**
 * Routes that are only accessible to Admins
 */
function AuthenticationRoutes() {
  // const dispatchToRedux = useDispatch();

  const token = Cookies.get('AccessToken');
  const user = useSelector((state) => state.user);

  const handleMount = () => {
    const hasAccess = token?.length > 0
      && user?.userProfile?.typ === 'PrivateApi'
      && (user?.userProfile?.user_permission === 'Admin');

    // console.log('Auth Check', {
    //   token_length: token?.length > 0,
    //   user_type: user.userProfile.typ === 'PrivateApi',
    //   user_right: user.userProfile.user_right === 'Admin',
    // });

    // const tokens = {
    //   JWT: user.loginResponse.JwtToken,
    //   'Jwt-In-Your-Cookies': token,
    //   UserType: user.userProfile.typ,
    //   UserRight: user.userProfile.user_right,
    // };

    // console.log('Auth Router', tokens);
    // console.log('Auth JWT Check', tokens.JWT === tokens['Jwt-In-Your-Cookies']);

    // Clears pending request map when navigating to a different page.
    // clearPending();
    // console.log('Clear Pending Auth');

    // console.log('Auth Func', hasAccess);

    return hasAccess ? <Layout /> : <Navigate to="/login" />;
  };

  return {
    path: '/admin',
    element: handleMount(),
    children: [
      { path: 'user-management', element: <UserManagement /> },
      {
        path: 'debug',
        element: <EnableDebug />,
      },
      {
        path: 'disableDebug',
        element: <DisableDebug />,
      },
    ],
  };
}

export default AuthenticationRoutes;
