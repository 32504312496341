/* eslint-disable react/no-unescaped-entities */
import React from 'react';

function Release20250205() {
  return (
    <section className="flex flex-col gap-4 border-b">
      <h2 className="font-gothammedium text-neutral-700">Enrichment_User_Management</h2>
      <p className="-mt-4">05th Feb 2025</p>

      <h4>Notice:</h4>
      <h5>Releases Covered:</h5>
      <ul className="-mt-4">
        <li>Enrichment_24/User Management</li>
        <li>Enrichment_24/S20_Security Storage Accounts</li>
        <li>Enrichment_24/S23_Code-Freeze-Period</li>
        <li>Enrichment_25/S26_E01</li>
      </ul>

      <h3 className="font-gothammedium text-neutral-700">
        New Pages
      </h3>

      <h4>User Management</h4>

      <ul className="-mt-4">
        <li>New user Management Page</li>
        <li>New fluid and user friendly UI</li>
        <li>Ability for merch to add users to Prism in groups</li>
        <li>Clones Users</li>
        <li>Enhanced logs for any changes</li>
        <li>Allowing users with GL email address to be added to Prism</li>
        <li>Automatic SAML Email to Security when adding or enabling users</li>
      </ul>

      <h4>Colour Matching</h4>

      <ul className="-mt-4">
        <li>Allow users to create and map different colours</li>
      </ul>

      <h3 className="font-gothammedium text-neutral-700">
        New Functionality
      </h3>

      <ul className="-mt-4">
        <li>Change Prism Side Nav to Top Nav</li>
      </ul>

      {/* <p><strong>Central PIM access have been shut down.</strong></p> */}

      <h3 className="font-gothammedium text-neutral-700">Fixes & Improvements</h3>

      <ul className="-mt-4">
        <li>(Export All) - PLU's not populating in Excel Sheet</li>
        <li>Value upload time not matching</li>
        <li>(Enable Stock) - French language displaying for FP Italy</li>
        <li>(PDP) - Release date updating one hour out</li>
        <li>(Attributes Def) - Remove Site selection from outdoor group</li>
        <li>Selected locations only filter not working in Prism</li>
        <li>(Dropship Wizard) - Hide Enable Fulfilment Page</li>
        <li>(PDP) - Translated Product Description not Updating</li>
        <li>(PDP) - Description section disappearing after attribute flags are changed</li>
      </ul>
    </section>
  );
}

export default Release20250205;
