import {
  Box,
  Checkbox, Divider,
  Typography,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import React from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import postEnableUser from '../../../api/private/users_management/posts/postEnableUser';
import postUpdateUser from '../../../api/private/users_management/posts/postUpdateUser';
import Modal from '../../../components/Modal';

function ViewEditUserModal({
  open,
  handleClose,
  userTypesMapping,
  apiAccessTypesMapping,
  userPermissionMapping,
  selectedUser,
  setValueChanged,
  userStatus,
  type,
}) {
  // eslint-disable-next-line no-control-regex
  const emailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/g;
  const [email, setEmail] = React.useState(selectedUser.UserName);
  const [username, setUsername] = React.useState(selectedUser.AccountName);
  const [userType, setUserType] = React.useState(userTypesMapping[selectedUser.UserType]);
  const [chosenGroup, setChosenGroup] = React.useState([]);
  const [apiAccessType, setApiAccessType] = React.useState(apiAccessTypesMapping[selectedUser.ApiAccessType]);
  const [userPermission, setUserPermission] = React.useState(userPermissionMapping[selectedUser.UserPermission]);

  const user = useSelector((state) => state.user.userProfile);
  const sites = useSelector((state) => state.fascias);

  const defaultFaciaOptions = sites.flatMap((obj) => obj.Sites).filter((obj) => selectedUser.SiteIds.includes(obj.SiteId)).map((obj) => ({ label: obj.Name, value: obj.SiteId }));

  const [siteIds, setSitesIds] = React.useState(selectedUser.SiteIds);
  const groups = useSelector((state) => state.groups.groupList);
  const [selectedGroup, setSelectedGroup] = React.useState(groups.filter((obj) => obj.Id === selectedUser.GroupId)[0]);

  const [errorExists, setErrorExists] = React.useState(false);

  const initialValues = React.useRef({
    username,
    userType,
    apiAccessType,
    email,
    selectedGroup,
    userPermission,
    siteIds,
  });
  // Function to check if the form values have changed
  const hasChanges = () => (
    username !== initialValues.current.username
    || userType !== initialValues.current.userType
    || apiAccessType !== initialValues.current.apiAccessType
    || email !== initialValues.current.email
    || JSON.stringify(selectedGroup) !== JSON.stringify(initialValues.current.selectedGroup)
    || userPermission !== initialValues.current.userPermission
    || JSON.stringify(siteIds) !== JSON.stringify(initialValues.current.siteIds)
  );

  const disableCreateButton = () => {
    const emptyUsername = username?.length === 0;
    const emptyUserType = userType == null;
    const emptyApiAccessType = apiAccessType == null;
    const emptyEmail = email?.length === 0;
    const emptyChosenGroup = selectedGroup == null;
    const emptyUserPermission = userPermission === null;
    const emptyFacias = siteIds?.length === 0;

    const disableSave = user.user_permission === 'GroupAdmin' && user.user_groupId !== selectedUser.GroupId;
    if (type === 'View') {
      const formIsInvalid = emptyUsername
        || emptyUserType
        || emptyApiAccessType
        || emptyEmail
        || errorExists
        || emptyChosenGroup
        || emptyUserPermission
        || emptyFacias;
      return formIsInvalid || !hasChanges() || disableSave;
    }

    const formIsInvalid = emptyUsername
        || emptyUserType
        || emptyApiAccessType
        || emptyEmail
        || errorExists
        || emptyChosenGroup
        || emptyUserPermission
        || emptyFacias;
    return formIsInvalid || disableSave;
  };

  const saveUser = async () => {
    if (type === 'View') {
      await postUpdateUser(
        username,
        userType,
        userPermission,
        siteIds,
        apiAccessType,
        selectedGroup.Id,
        selectedGroup.GroupName,
        selectedUser.Id,
        userStatus[selectedUser.Status],
      )
        .then(() => {
          handleClose(false);
          toast.success('Successfully updated user');
          setValueChanged(true);
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      await postEnableUser(
        username,
        userType,
        userPermission,
        siteIds,
        apiAccessType,
        selectedGroup.Id,
        selectedGroup.GroupName,
        selectedUser.Id,
        userStatus[selectedUser.Status],
      )
        .then(() => {
          handleClose(false);
          toast.success('Successfully enabled user');
          setValueChanged(true);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  const sitesFlattened = sites.flatMap((obj) => obj.Sites);
  const defaultgroupSites = sitesFlattened
    .filter((obj) => selectedGroup?.SiteIds?.includes(obj?.SiteId))
    .map((obj) => ({
      label: obj.Name,
      value: obj.SiteId,
    }));
  const [selectedOptions, setSelectedOptions] = React.useState(defaultFaciaOptions);
  const allSelected = selectedOptions?.length === chosenGroup?.SiteIds?.length;
  const isIndeterminate = selectedOptions.length > 0 && !allSelected;
  const [customOptions, setCustomOptions] = React.useState([{ label: 'Select All', value: 'all' }, ...defaultgroupSites]);

  const handleFaciaChange = (event, values) => {
    const isSelectAllSelected = values.some((option) => option.label === 'Select All');
    const allOptionsSelected = customOptions.filter((option) => option.label !== 'Select All');

    if (isSelectAllSelected) {
      if (selectedOptions.length === allOptionsSelected.length) {
        setSelectedOptions([]);
      } else {
        setSelectedOptions(allOptionsSelected);
        setSitesIds(allOptionsSelected.map((option) => option.value));
      }
    } else {
      const uniqueValues = values.filter(
        (option, index, self) => option.label !== 'Select All'
          && index === self.findIndex((o) => o.value === option.value),
      );

      setSelectedOptions(uniqueValues);
      setSitesIds(uniqueValues.map((option) => option.value));
    }
  };

  const handleGroupChange = (value) => {
    setSelectedOptions([]);
    const groupSites = sitesFlattened
      .filter((obj) => value?.SiteIds?.includes(obj?.SiteId))
      .map((obj) => ({
        label: obj.Name,
        value: obj.SiteId,
      }));
    setCustomOptions([{ label: 'Select All', value: 'all' }, ...groupSites]);
    setSelectedGroup(value);
    setSitesIds([]);
  };

  React.useEffect(() => {
    if ((!email.includes('@jdplc.com') && !email.includes('@cosmossport.gr') && !email.includes('@globallogic.com')) && userType === 1) {
      setErrorExists(true);
    } else {
      setErrorExists(false);
    }
  }, [email]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      title="View/Edit User"
      titleVariant="h4"
      buttonLabel={(type === 'Enabled') ? 'Enable' : 'Save'}
      buttonOnClick={() => saveUser()}
      disableButton={disableCreateButton()}
    >
      <Box display="flex" gap={2}>
        <Stack spacing={3} sx={{ width: 600 }}>
          <Typography variant="h5">User Details</Typography>
          <TextField
            required
            value={email}
            label="Email"
            fullWidth
            type="email"
            error={email.length > 0 && (!email.match(emailRegex) || errorExists)}
            onChange={(event) => setEmail(event.target.value)}
            helperText={email.length > 0 && errorExists ? 'Invalid email address' : ''}
            disabled
          />
          <TextField
            required
            label="Full Name"
            fullWidth
            value={username}
            InputLabelProps={{ shrink: username?.length > 0 }}
            onChange={(event) => setUsername(event.target.value)}
          />
          <Stack direction="row" spacing={2}>
            <Autocomplete
              fullWidth
              value={selectedGroup}
              display="flex"
              id="tags-standard"
              options={
                user.user_permission === 'Admin'
                  ? groups
                  : groups.filter((obj) => obj.Id === user.user_groupId)
              }
              getOptionLabel={(option) => (`${option.GroupName}`) || ''}
              onChange={(event, value) => {
                setChosenGroup(value);
                handleGroupChange(value);
              }}
              renderInput={(params) => <TextField required {...params} label="Group" />}
            />
            <FormControl fullWidth>
              <InputLabel required id="user-permission">User Permission</InputLabel>
              <Select
                labelId="user-permission"
                label="User Permission"
                value={userPermission}
                onChange={(event) => setUserPermission(event.target.value)}
              >
                {
                  // Filter out 'admin' permission if the user is not an admin
                  Object.entries(userPermissionMapping)
                    .filter(([key]) => (user.user_permission === 'GroupAdmin' ? key !== 'Admin' : true))
                    .map(([key, value]) => (
                      <MenuItem key={value} value={value}>
                        {key}
                        {' '}
                        {/* Display the key (permission name) */}
                      </MenuItem>
                    ))
                }
              </Select>
            </FormControl>
          </Stack>
          <Typography variant="h5">Settings</Typography>
          <Stack direction="row" spacing={2}>
            <FormControl
              fullWidth
              error={email.length > 0 && (!email.includes('@jdplc.com') && !email.includes('@cosmossport.gr')
                && !email.includes('@globallogic.com')) && userType === 1}
              disabled={user.user_permission !== 'Admin'}
            >
              <InputLabel id="user-type">User Type</InputLabel>
              <Select
                labelId="user-type"
                label="User Type"
                value={userType}
                onChange={(event) => setUserType(event.target.value)}
              >
                {(Object.entries(userTypesMapping)).map(([key, value]) => (
                  <MenuItem value={value}>{key}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth disabled={user.user_permission !== 'Admin'}>
              <InputLabel id="access-type">Access Type</InputLabel>
              <Select
                labelId="access-type"
                label="Access Type"
                value={apiAccessType}
                onChange={(event) => setApiAccessType(event.target.value)}
              >
                {(Object.entries(apiAccessTypesMapping)).map(([key, value]) => (
                  <MenuItem value={value}>{key}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        </Stack>
        <Divider orientation="vertical" flexItem />
        <Box display="flex" alignItems="flex-start">
          <Stack spacing={3}>
            <Typography variant="h5">Access</Typography>
            <Autocomplete
              fullWidth
              sx={{
                minWidth: 200, maxWidth: 400, maxHeight: 300, overflow: 'auto',
              }}
              multiple
              options={customOptions}
              value={selectedOptions}
              onChange={handleFaciaChange}
              disableCloseOnSelect
              getOptionLabel={(option) => option.label}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    checked={
                      option.label === 'Select All'
                        ? allSelected
                        : selected
                    }
                    indeterminate={option.label === 'Select All' && isIndeterminate}
                  />
                  {option.label}
                </li>
              )}
              renderInput={(params) => (
                <TextField required {...params} variant="outlined" label="Facias" />
              )}
            />
          </Stack>
        </Box>
      </Box>
    </Modal>
  );
}

export default ViewEditUserModal;
