const SidebarAccordions = [{
  productInfo: [
    // { link: '/product-info/products', name: 'Products Table' },
  ],
  bulkOperations: [
    // Add bulk items here as needed
  ],
  dropship: [
    // { link: '/dropship/wizards/product', name: 'Product Wizard' },
  ],
  dataManagement: [
    { link: '/data-management/product-type', name: 'Product Type' },
    { link: '/data-management/attributes-definition', name: 'Attributes Definition' },
    { link: '/data-management/category', name: 'Category' },
    { link: '/data-management/brand-grouping', name: 'Brand Grouping' },
    { link: '/data-management/size-mapping', name: 'Size Mapping' },
    { link: '/data-management/colour-matching', name: 'Colour Matching' },
  ],
  styles: [
    // { link: '/styles', name: 'Styles' },
  ],
  images: [
    { link: '/product-info/media-check', name: 'Media Checker' },
    { link: '/image-checker', name: 'Amplience Image Checker' },
  ],
  settings: [
    { link: '/settings/indexes', name: 'Indexing' },
    { link: '/settings/user-management', name: 'User Management' },
    { link: '/settings/release-notes', name: 'Release Notes' },
  ],
}];

export default SidebarAccordions;
