import DeleteIcon from '@mui/icons-material/Delete';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import React, {
  useEffect, useReducer, useRef, useState,
} from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';

import removeProductCategories from '../../../../../../api/oc_product/product/attributes/categories/deletes/removeProductCategories';
import removeProductCategory from '../../../../../../api/oc_product/product/attributes/categories/deletes/removeProductCategory';
import getSelectedCategories from '../../../../../../api/oc_product/product/attributes/categories/gets/getSelectedCategories';
import patchPrimaryCategory from '../../../../../../api/oc_product/product/attributes/categories/patches/patchPrimaryCategory';
import assginCategoriesToProduct from '../../../../../../api/oc_product/product/attributes/categories/posts/assignCategoriesToProduct';
import Modal from '../../../../../../components/Modal';
import CategoryMenu from '../../../../../bulk_operations/categories/components/CategoryMenu';

const reducer = (state, action) => {
  switch (action.type) {
    case 'ACTION':
      return {
        ...state,
        action: action.payload,
      };
    case 'CATEGORIES':
      return {
        ...state,
        categoryId: action.payload,
      };
    case 'REFERENCES':
      return {
        ...state,
        referenceIds: action.payload,
      };
    case 'RESET':
      return action.payload;
    default:
      return state;
  }
};

const useStyles = makeStyles(({
  chipRoot: {
    '& .MuiChip-icon': {
      order: 1,
      marginRight: '8px !important',
      cursor: 'pointer',
      marginLeft: '0 !important',
    },
    height: 'auto',
    '& .MuiChip-label': {
      display: 'block',
      whiteSpace: 'normal',
    },
    '& .MuiChip-deleteIcon': {
      order: 2,
    },
  },
}));

function Categories() {
  const { id } = useParams();
  const siteId = useSelector((state) => state.user.loginResponse.selectedSite.SiteId);
  const languageId = useSelector((state) => state.language?.language?.LanguageId);
  const styles = useStyles();
  const submitBtn = React.useRef(null);

  const [selectedCats, setSelectedCats] = useState([]);
  const [selectedAction, setSelectedAction] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [modalText, setModalText] = useState(null);
  const [modalTitle, setModalTitle] = useState(null);
  const [buttonWidth, setButtonWidth] = React.useState(0);
  const [buttonHeight, setButtonHeight] = React.useState(0);
  const [loading, setLoading] = useState(false);
  const initialState = {
    referenceIds: '',
    categoryId: [],
    action: '',
  };
  const [state, dispatch] = useReducer(reducer, initialState);
  const treeRef = useRef(null);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (state.action === 4) { // delete
      removeProductCategories({
        productId: id,
        categoryIds: state.categoryId.map((obj) => obj.Id),
        siteId,
      })
        .then(() => {
          toast.success('Categories deleted', { autoClose: 2000 });
          getSelectedCategories(id, siteId, languageId)
            .then((res) => {
              if (res) {
                setSelectedCats(res.data);
              }
            });
        })
        .catch((err) => console.error(err))
        .finally(() => {
          setLoading(false);
          setOpenModal(false);
          dispatch({ type: 'RESET', payload: initialState });
        });
    } else {
      assginCategoriesToProduct({
        productId: id,
        categoryIds: state.categoryId.map((obj) => obj.Id),
        siteId,
      })
        .then(() => {
          toast.success('Categories added', { autoClose: 2000 });
          getSelectedCategories(id, siteId, languageId)
            .then((res) => {
              if (res) {
                setSelectedCats(res.data);
              }
            });
        })
        .catch((err) => console.error(err))
        .finally(() => {
          setLoading(false);
          setOpenModal(false);
          dispatch({ type: 'RESET', payload: initialState });
        });
    }
  };
  const handleStarClick = (chipName) => {
    const selectedCat = selectedCats.find((obj) => obj.FullCategoryPath === chipName);
    // Setting the modal text
    if (selectedCat.IsPrimary) {
      setModalText(`This will remove ${selectedCat.Category} as the primary category`);
      setModalTitle(`Remove ${selectedCat.Category} as Primary`);
    } else {
      setModalText(`Only one category can be set as primary. This will set ${selectedCat.Category} as the only primary category`);
      setModalTitle(`Set ${selectedCat.Category} as Primary`);
    }
    setSelectedAction(() => () => {
      setLoading(true);
      patchPrimaryCategory(id, siteId, selectedCat.Id)
        .then(() => {
          toast.success('Category updated', { autoClose: 2000 });
          getSelectedCategories(id, siteId, languageId)
            .then((res) => {
              if (res) {
                setSelectedCats(res.data);
              }
            });
        })
        .catch((err) => console.error(err))
        .finally(() => {
          setLoading(false);
          setOpenModal(false);
        });
    });
    setOpenModal(true);
  };

  // If the 'x' is clicked, remove the item from the selectedCats array
  const handleDelete = (item) => {
    const catToRemove = selectedCats.find((obj) => obj.FullCategoryPath === item);
    // Setting the modal text
    setModalText('Are you sure you want to remove this category?');
    setSelectedAction(() => () => {
      setLoading(true);
      removeProductCategory(id, siteId, catToRemove.Id)
        .then(() => {
          toast.success('Category removed', { autoClose: 2000 });
          getSelectedCategories(id, siteId, languageId)
            .then((res) => {
              if (res) {
                setSelectedCats(res.data);
              }
            });
        })
        .catch(() => {
          toast.error('Category removal failed', { autoClose: 2000 });
        })
        .finally(() => {
          setLoading(false);
          setOpenModal(false);
        });
    });
    setOpenModal(true);
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    getSelectedCategories(id, siteId, languageId)
      .then((res) => {
        if (res) {
          setSelectedCats(res.data);
        }
      });
  }, [id, siteId]);

  React.useEffect(() => {
    treeRef.current.resetSelectedCategories();
    dispatch({ type: 'RESET', payload: initialState });
  }, [siteId]);
  React.useEffect(() => {
    if (submitBtn.current) {
      const sbmBtn = submitBtn.current.getBoundingClientRect();
      setButtonWidth(sbmBtn.width);
      setButtonHeight(sbmBtn.height);
    }
  }, [submitBtn.current]);

  return (
    <div className="flex flex-col w-full h-[calc(100vh-100px)] lg:flex-row">
      <div className="overflow-y-auto w-full lg:w-[30%] mb-5">
        <Card variant="outlined" className="w-full h-full p-4">
          <Stack spacing={2} className="h-full">
            <Typography variant="body2" gutterBottom className="font-gothambook">
              Click the star icon to assign it as Primary Category or click the bin icon to delete it.
            </Typography>
            <Stack spacing={1} className="p-2 border rounded-md" sx={{ maxHeight: 700, overflow: 'auto' }}>
              {selectedCats.map((item) => (
                <Tooltip title={item.FullCategoryPath} arrow>
                  <Chip
                    classes={{ root: styles.chipRoot }}
                    sx={{ background: item.IsPrimary ? 'rgba(255,215,0,0.8)' : 'default' }}
                    label={item.FullCategoryPath}
                    key={item.id}
                    onDelete={() => handleDelete(item.FullCategoryPath)}
                    deleteIcon={(
                      <DeleteIcon />
                    )}
                    icon={
                      item.IsPrimary ? (
                        <StarIcon onClick={() => handleStarClick(item.FullCategoryPath)} />
                      ) : (
                        <StarBorderIcon onClick={() => handleStarClick(item.FullCategoryPath)} />
                      )
                    }
                    className="w-fit"
                  />
                </Tooltip>
              ))}
            </Stack>
            {selectedAction && (
              <Modal
                title={modalTitle}
                open={openModal}
                onClose={handleModalClose}
                buttonLabel="Confirm"
                buttonOnClick={selectedAction}
                buttonLoading={loading}
              >
                <Typography>{modalText}</Typography>
              </Modal>
            )}
          </Stack>
        </Card>
      </div>
      <Divider
        orientation="vertical"
        className="mx-5"
      />
      <div className="lg:w-[70%]">
        <section className="flex justify-center w-full gap-8">
          <div className="w-full h-full">
            <Typography gutterBottom>
              Click on category to select.
            </Typography>

            <CategoryMenu
              ref={treeRef}
              enableSelect
              onSelectedValue={(value) => dispatch({ type: 'CATEGORIES', payload: value })}
              className="max-h-[calc(100vh-140px)] border rounded-lg bg-white pr-8"
            />
          </div>
          <div className="lg:w-[80%] overflow-y-auto">
            <form className="flex flex-col w-full h-full gap-4" onSubmit={handleSubmit}>
              <Typography gutterBottom>Selected Categories.</Typography>
              <Box className="flex flex-wrap gap-2 p-4 border border-gray-400 rounded-md">
                {state.categoryId.length > 0 ? (
                  state.categoryId.map((category) => (
                    <Tooltip title={category.FullCategoryPath} arrow>
                      <Chip
                        key={category.FullCategoryPath}
                        label={category.FullCategoryPath}
                        onDelete={() => treeRef.current.removeCategory(category)}
                        size="small"
                      />
                    </Tooltip>
                  ))
                ) : (
                  <Chip
                    label="No Categories Selected"
                    className="bg-transparent"
                  />
                )}
              </Box>

              <FormControl fullWidth>
                <InputLabel id="action-label">Action</InputLabel>
                <Select
                  labelId="action-label"
                  id="action-select"
                  label="Action"
                  value={state.action}
                  disabled={state.categoryId.length === 0}
                  onChange={(e) => dispatch({ type: 'ACTION', payload: e.target.value })}
                >
                  <MenuItem value={3}>Update</MenuItem>
                  <MenuItem value={4}>Delete</MenuItem>
                </Select>
              </FormControl>
              <div className="flex justify-end w-full gap-10">
                <Button onClick={() => {
                  treeRef.current.resetSelectedCategories();
                  dispatch({ type: 'RESET', payload: initialState });
                }}
                >
                  Reset
                </Button>
                <div className="relative float-right w-max">
                  <Button
                    ref={submitBtn}
                    variant="contained"
                    type="submit"
                    id="submit-button"
                    disabled={state.action === '' || state.categoryId === null}
                    className="z-10"
                  >
                    Submit
                  </Button>
                  {(!state.action === '') && (
                    <div
                      className="absolute left-0 right-0 z-0 ml-auto mr-auto rounded top-1 bg-primary-400 animate-ping"
                      style={{ width: `${buttonWidth / 1.5}px`, height: `${buttonHeight / 1.25}px` }}
                    />
                  )}
                </div>
              </div>
            </form>

          </div>

        </section>
      </div>
    </div>
  );
}

export default Categories;
