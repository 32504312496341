import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';

function Model({
  title = '',
  titleVariant = 'h5',
  children,
  buttonLabel = '',
  buttonOnClick,
  buttonLoading = false,
  open,
  onClose,
  containerWidth = 'lg',
  gridSpacing = 4,
  disableButton = false,
  desktopModalPadding = '4rem',
  desktopModalWidth = '40%',
  mobileModalPadding = '2rem 1rem',
  mobileModalWidth = '90%',
  desktopModalHeight,
}) {
  const isDesktop = useMediaQuery('(min-width:600px)');

  const mobileModalStyles = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    border: '2px solid grey',
    borderRadius: '10px',
    padding: mobileModalPadding,
    width: mobileModalWidth,
    overflow: 'scroll',
  };
  const desktopModalStyles = {
    position: 'relative',
    top: '10%',
    left: '20%',
    backgroundColor: 'white',
    border: '2px solid grey',
    borderRadius: '10px',
    padding: desktopModalPadding,
    width: desktopModalWidth,
    height: desktopModalHeight,
    overflow: 'scroll',
  };

  return (
    <Modal open={open} onClose={() => onClose(false)}>
      <Box sx={isDesktop ? desktopModalStyles : mobileModalStyles}>
        <CloseIcon
          onClick={() => onClose(false)}
          className="absolute cursor-pointer top-4 right-4"
        />
        <Container maxWidth={containerWidth}>
          <Grid container spacing={gridSpacing}>
            {title && (
              <Grid item xs={12}>
                <Typography
                  id="modal-modal-title"
                  variant={titleVariant}
                  textAlign="center"
                >
                  {title}
                </Typography>
              </Grid>
            )}
            <Grid item xs={12} sx={{ maxHeight: 'calc(100vh - 200px)' }}>
              {children}
            </Grid>
            {buttonLabel.length > 0 && (
              <Grid item xs={12}>
                <Stack spacing={2} direction="row" justifyContent="end" alignItems="center">
                  <Button
                    onClick={() => onClose(false)}
                    variant="text"
                    className="bold"
                  >
                    Cancel
                  </Button>
                  <LoadingButton
                    onClick={buttonOnClick}
                    variant="contained"
                    color="success"
                    className="bold"
                    disabled={disableButton || buttonLoading}
                    loading={buttonLoading}
                  >
                    {buttonLabel}
                  </LoadingButton>
                </Stack>
              </Grid>
            )}
          </Grid>
        </Container>
      </Box>
    </Modal>
  );
}

export default Model;
