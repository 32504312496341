import { FormControl, TextField } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import Container from '@mui/material/Container';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React from 'react';
import { toast } from 'react-toastify';

import getSites from '../../../api/private/users_management/gets/getAllFacias';
import postAddGroup from '../../../api/private/users_management/posts/postAddGroup';
import Modal from '../../../components/Modal';

function NewGroupModal({
  onAddSuccess,
  open,
  handleClose,
}) {
  const [siteNames, setSiteNames] = React.useState([]);
  const [combinedSites, setCombinedSites] = React.useState([]);
  const [groupName, setGroupName] = React.useState('');
  const [siteIds, setSiteIds] = React.useState([]);

  const isValidGroupName = (groupname) => /^[A-Za-z\s]*$/.test(groupname);

  const disableCreateButton = () => {
    const emptygroupName = groupName?.length === 0;
    const emptysiteNames = siteNames?.length === 0;

    return emptygroupName
      || emptygroupName
      || emptysiteNames;
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'right',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
  };

  const addGroup = async () => {
    await postAddGroup(groupName, siteIds, 1)
      .then(() => {
        handleClose(false);
        onAddSuccess(true);
        toast.success('Successfully created group');
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleSiteChange = (event) => {
    const {
      target: { value },
    } = event;
    if (value.includes('All')) {
      setSiteNames(
        siteNames.length === combinedSites.length ? [] : [...combinedSites.map((item) => item.siteName)],
      );
      setSiteIds(siteNames.length === combinedSites.length ? [] : [...combinedSites.map((site) => site.siteId)]);
    } else {
      setSiteNames(
        typeof value === 'string' ? value.split(',') : value,
      );
      const selectedValues = value.map((name) => combinedSites.find((item) => item.siteName === name)?.siteId);
      setSiteIds(selectedValues);
    }
  };

  const combineSites = (data) => {
    const sites = [];
    if (Array.isArray(data)) {
      data.forEach((obj) => {
        obj.Sites.forEach((site) => {
          sites.push({
            ...site,
            parent: obj.Name,
            SiteGroupId: obj.SiteGroupId,
          });
        });
      });
      return sites;
    }
    return [];
  };

  React.useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const sites = await getSites();
        if (sites.data.length > 0) {
          const combined = combineSites(sites.data);
          setCombinedSites(combined.map((item) => (({ siteId: item.SiteId, siteName: item.Name }))));
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchInitialData();
  }, []);
  const handleDelete = (value) => {
    setSiteNames(
      ((prevSelected) => prevSelected.filter((item) => item !== value)),
    );
  };
  const boxStyle = {
    transition: '',
    padding: combinedSites.length > 0 ? '16px' : '8px',
    border: '1px solid ~90caf9',

  };
  const selectedAll = siteNames.length === combinedSites.length;
  const isValid = isValidGroupName(groupName);
  return (
    <div>
      <style>
        {`
          .custom-scrollbar {
            scrollbar-width: thin;
            scrollbar-color: #888 #f1f1f1;
          }

          .custom-scrollbar::-webkit-scrollbar {
            width: 10px;
          }

          .custom-scrollbar::-webkit-scrollbar-track {
            background: #f1f1f1;
          }

          .custom-scrollbar::-webkit-scrollbar-thumb {
            background-color: #888;
            border-radius: 10px;
            border: 2px solid #f1f1f1;
          }

          .custom-scrollbar::-webkit-scrollbar-thumb:hover {
            background-color: #555;
          }
        `}
      </style>
      <Container maxWidth="max">
        <Modal
          open={open}
          onAddSuccess={onAddSuccess}
          onClose={handleClose}
          title="Add New Group"
          buttonLabel="CREATE"
          buttonOnClick={() => addGroup()}
          disableButton={disableCreateButton()}
          leftPosition="30%"

        >
          <Stack spacing={3}>
            <FormControl sx={{ m: 9, width: 600 }}>
              <div>
                <Typography variant="h8">
                  Group Name
                </Typography>
                <TextField
                  size="small"
                  type="text"
                  lable="groupName"
                  className="w-full"
                  value={groupName}
                  error={!isValid}
                  onChange={(e) => setGroupName(e.target.value)}
                />
                {!isValid && (
                  <Typography
                    variant="body2"
                    color="error"
                    style={{ marginTop: '4px' }}
                  >
                    Group name must contain only letters and spaces.
                  </Typography>
                )}
              </div>
            </FormControl>

            <FormControl sx={{ m: 9, width: 600 }}>
              <div className="flex flex-col w-full">
                <Typography variant="h8" className="mb-3">Site Access</Typography>
                <Select
                  disabled={groupName?.length === 0 || !isValid}
                  labelId="multiple-checkbox-label"
                  id="multiple-checkbox"
                  multiple
                  value={siteNames}
                  onChange={handleSiteChange}
                  input={<OutlinedInput label="Sites" />}
                  renderValue={(selected) => (
                    <div
                      style={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(3, 1fr)',
                        gap: '5px',
                        maxHeight: '300px',
                        overflowX: 'hidden',
                        paddingRight: '8px',
                      }}
                      className="custom-scrollbar overflow-y-scroll max-h-52"
                    >
                      {
                        selected.map((value) => (
                          <Chip
                            variant="outlined"
                            className="w-full"
                            title={value}
                            key={value}
                            label={value}
                            onMouseDown={(e) => { e.stopPropagation(); }}
                            onDelete={(e) => { e.stopPropagation(); handleDelete(value); }}
                            onWheel={(e) => e.stopPropagation()}
                            size="small"
                            sx={{
                              backgroundColor: '#4682B4',
                              color: 'white',
                              fontWeight: 400,
                              '& .MuiChip-deleteIcon': { color: 'white', marginLeft: 'auto' },
                              maxWidth: '90%',
                              display: 'inline-flex',
                              whiteSpace: 'nowrap',
                              height: '30px',
                              textOverflow: 'ellipsis',
                              fontSize: '1rem',
                              padding: '0 10px',
                              overflow: 'hidden',
                              alignItems: 'center',
                              borderRadius: '20px',
                            }}
                          />
                        ))
                      }

                    </div>
                  )}
                  MenuProps={MenuProps}
                  sx={boxStyle}
                >
                  <MenuItem value="All">
                    <Checkbox checked={selectedAll} />
                    <ListItemText primary="All" />
                  </MenuItem>
                  {combinedSites.map((sites) => (
                    <MenuItem key={sites.siteId} value={sites.siteName}>
                      <Checkbox checked={siteNames.includes(sites.siteName)} />
                      <ListItemText primary={sites.siteName} />
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </FormControl>
          </Stack>
        </Modal>
      </Container>
    </div>
  );
}

export default NewGroupModal;
