import LogoutIcon from '@mui/icons-material/Logout';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Cookies from 'js-cookie';
import React from 'react';
import { useDispatch } from 'react-redux';

import postRevokeRefreshToken from '../../api/private/users/posts/postRevokeRefreshToken';
import postRevokeToken from '../../api/private/users/posts/postRevokeToken';
import { getLocalStorage, setLocalStorage } from '../../helper/LocalStorage';
import { removeSessionStorage } from '../../helper/SessionStorage';
import { purgeUser } from '../../redux/user/User';

function LogoutButton(backgroundColour) {
  const dispatch = useDispatch();

  const handleLogout = async () => {
    // eslint-disable-next-line no-console
    console.info('Logging Out User');
    const refreshToken = localStorage.getItem('RefreshToken');
    if (refreshToken && refreshToken !== 'undefined') {
      await postRevokeRefreshToken(getLocalStorage('RefreshToken'));
    }
    await postRevokeToken(Cookies.get('AccessToken'));
    await dispatch(purgeUser());
    if (getLocalStorage('logout-event') !== undefined) {
      setLocalStorage('logout-event', `logout${Math.random()}`);
    }
    removeSessionStorage('bg-user');
    removeSessionStorage('custom-background-sidebar');
    removeSessionStorage('hasRunLoginLogic');
    window.location.href = `${window.location.protocol}//${window.location.host}/login`;
  };

  return (
    <>
      <ListItemIcon
        sx={{ color: backgroundColour }}
      >
        <LogoutIcon className="w-5" />
      </ListItemIcon>
      <ListItemText
        primary="Logout"
        disableTypography
        onClick={() => handleLogout()}
      />
    </>
  );
}
export default LogoutButton;
