import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { DataGrid, GridToolbarContainer, GridToolbarExportContainer } from '@mui/x-data-grid';
import React from 'react';
import { useSelector } from 'react-redux';

import getTransactions from '../../../api/oc_product/bulk/bulk_process/sites/reports/transactions/getTransactions';
import parseDateString from '../../../utils/parseDateString';
import Modal from '../../Modal';
import ExportJobButton from './ExportJobButton';

function msToTime(ms) {
  const seconds = (ms / 1000).toFixed(2);
  const minutes = (ms / (1000 * 60)).toFixed(2);
  const hours = (ms / (1000 * 60 * 60)).toFixed(2);
  const days = (ms / (1000 * 60 * 60 * 24)).toFixed(2);
  if (seconds < 60) return `${seconds} seconds`;
  if (minutes < 60) return `${minutes} minutes`;
  if (hours < 24) return `${hours} hours`;
  return `${days} days`;
}

function CustomExportButton({ taskId, siteId }) {
  return (
    <GridToolbarExportContainer>
      <ExportJobButton
        taskId={taskId}
        siteId={siteId}
      />
    </GridToolbarExportContainer>
  );
}

function CustomToolbar(props, taskId, siteId) {
  return (
    <GridToolbarContainer {...props}>
      <CustomExportButton
        taskId={taskId}
        siteId={siteId}
      />
    </GridToolbarContainer>
  );
}

export default function JobModal({
  id,
  open,
  setIsOpen,
  parentData,
}) {
  // const siteId = useSelector((state) => state.user.loginResponse.selectedSite.SiteId);

  const { transaction_types, operation_types } = useSelector((state) => state.bulk_operations);

  const [processId, setProcessId] = React.useState(0);

  const [rows, setRows] = React.useState([]);
  const [columns, setColumns] = React.useState([]);
  const [operation, setOperation] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  const [totalRows, setTotalRows] = React.useState(0);

  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 25,
    page: 0,
  });

  let parsedHeaderInfo = null;
  if (parentData.BulkOperationRequestType === 34) {
    try {
      parsedHeaderInfo = JSON.parse(parentData.HeaderInfo);
    } catch (e) {
      console.error('Invalid JSON in HeaderInfo', e);
    }
  }

  // Single Selected Attributes (ProductId)(pin): 1
  // Single Selected Attributes (PluId & SourceId)(pin): 2
  // Multi Selected Attributes (ProductId)(pin): 3
  // Multi Selected Attributes (PluId & SourceId)(pin): 4
  // Campaign (ProductId)(pin): 5
  // Campaign (PluId & SourceId)(pin): 6
  // Collection (ProductId)(pin): 7
  // Collection (PluId & SourceId)(pin): 8
  // Product Type (PluId & SourceId)(pin): 9
  // Product Type (ProductId)(pin): 10
  // Categories (ProductId)(pin): 11
  // Categories (PluId & SourceId)(pin): 12
  // Single Line Attributes (PluId)(pin): 13
  // Single Line Attributes (ProductId)(pin): 14
  // Attributes (PluId & SourceId)(pin): 15
  // Product Name (PluId & SourceId)(pin): 16
  // Product Name (ProductId)(pin): 17
  // Products status Approve(pin): 18
  // Primary Category (PluId & SourceId)(pin): 19
  // Primary Category (ProductId)(pin): 20
  // Product Release Date (PluId)(pin): 21
  // Products enable/disable Stocks(pin): 22
  // Product Release Date (ProductId)(pin): 23

  const showAttributeNamePages = [1, 2, 3, 4, 5, 6, 7, 8, 13, 14, 15];

  function formatTransactionLog(data) {
    const formattedData = data?.map((item) => {
      const keyValuePairs = Object.entries(item).map(([key, value]) => `${key}: ${value}`);
      return keyValuePairs.join(', ');
    });

    const result = formattedData.join('.\n');
    return result;
  }

  function formatSubTransactionsObj(obj) {
    const nonZeroValues = Object.entries(obj)
      // eslint-disable-next-line no-unused-vars
      .filter(([key, value]) => value !== 0);

    const formattedSentence = nonZeroValues.map(([key, value]) => `${key}: ${value}`).join(', ');

    return formattedSentence;
  }

  const handleTableColumns = (columnsArray) => {
    const notNeededColumns = ['SiteId', 'SiteGroupId', 'BulkProcessId', 'BulkOperationRequestType'];
    if (parentData.SourceId === 0) {
      notNeededColumns.push('PluId');
    } else {
      notNeededColumns.push('ProductId');
    }
    if (parentData.BulkOperationRequestType === 33) {
      notNeededColumns.push('AttributeKeyValue');
    }
    if (parentData.BulkOperationRequestType === 34) {
      notNeededColumns.push('AttributeKeyValue');
      notNeededColumns.push('SubTransactionsSummary');
    }
    if ((parentData.BulkOperationRequestType === 14 || parentData.BulkOperationRequestType === 13) && parentData.CrudType === 4) {
      notNeededColumns.push('AttributeKeyValue');
      notNeededColumns.push('SubTransactionsSummary');
    }
    if (!showAttributeNamePages.includes(parentData.BulkOperationRequestType)) {
      notNeededColumns.push('AttributeName');
    }
    const filteredColumns = columnsArray.filter((str) => !notNeededColumns.includes(str));
    const tableColumns = filteredColumns.map((str) => {
      switch (str) {
        case 'TransactionId':
          return {
            flex: 1,
            minWidth: 220,
            field: str,
            headerName: str,
          };
        case 'TransactionStatus':
          return {
            flex: 1,
            minWidth: 140,
            field: str,
            headerName: str,
            renderCell: (params) => (params.value ? 'Success' : 'Failed'),
          };
        case 'TransactionLog':
          return {
            flex: 1,
            minWidth: 200,
            field: str,
            headerName: str,
            renderCell: (params) => {
              if (params.row.AttributeName === 'Create Pre Release Product' || params.row.AttributeName === 'Refreshed') {
                let isValidJSON = true;
                try { JSON.parse(params?.value); } catch { isValidJSON = false; }
                return (
                  <Tooltip
                    title={(
                      <p className="text-sm">{(isValidJSON) ? formatTransactionLog(JSON.parse(params?.value)) : params?.value }</p>
                    )}
                  >
                    <p className="truncate">{(isValidJSON) ? formatTransactionLog(JSON.parse(params?.value)) : params?.value }</p>
                  </Tooltip>
                );
              }
              const valSplit = params?.value.split(',').join(' ');
              return <p className="whitespace-pre-line">{valSplit}</p>;
            },
          };
        case 'SubTransactionsSummary':
          return {
            flex: 1,
            minWidth: 200,
            field: str,
            headerName: str,
            renderCell: (params) => {
              if (
                (params.row.AttributeName === 'Create Pre Release Product' && params.row.BulkOperationRequestType !== 21)
                || params.row.AttributeName === 'Refreshed'
                || (params.row.AttributeName === 'Enable-Disable Stock' && params.row.SubTransactionsSummary)) {
                return (
                  <Tooltip
                    title={(
                      <p className="text-sm">{params?.value !== null ? formatSubTransactionsObj(params?.value) : params?.value}</p>
                    )}
                  >
                    <p className="truncate">{params?.value !== null ? formatSubTransactionsObj(params?.value) : params?.value}</p>
                  </Tooltip>
                );
              }
              return <p className="whitespace-pre-line">{params?.value}</p>;
            },
          };
        case 'ProductTransactionType':
          return {
            flex: 1,
            minWidth: 200,
            field: str,
            headerName: str,
            renderCell: (params) => Object.keys(transaction_types ?? {}).find((key) => transaction_types[key] === params.value),
          };
        case 'InsertDate':
          return {
            flex: 1,
            minWidth: 200,
            field: str,
            headerName: str,
            renderCell: (params) => parseDateString(params.value),
          };
        case 'AttributeName':
          return {
            flex: 1,
            minWidth: 200,
            field: str,
            headerName: str,
          };
        case 'AttributeKeyValue':
          return {
            flex: 1,
            minWidth: 150,
            field: str,
            headerName: 'Value',
            renderCell: (params) => <p>{params.value}</p>,
          };

        default:
          return {
            flex: 1,
            minWidth: 100,
            field: str,
            headerName: str,
          };
      }
    });
    return tableColumns;
  };

  const [rowCountState, setRowCountState] = React.useState(totalRows);
  React.useEffect(() => {
    setRowCountState((prevRowCountState) => (totalRows !== undefined ? totalRows : prevRowCountState));
  }, [totalRows, setRowCountState]);

  React.useEffect(() => {
    setLoading(true);
    setRows([]);
    getTransactions(id, parentData.SiteId, (paginationModel.page + 1), paginationModel.pageSize, null)
      .then((res) => {
        const columnsSet = new Set();
        for (let i = 0; i < res.data.Data.length; i++) {
          const obj = res.data.Data[i];
          const values = Object.entries(obj);
          for (let j = 0; j < values.length; j++) {
            const column = values[j][0];
            columnsSet.add(column);
          }
        }

        const operationType = Object.keys(operation_types ?? {}).find((key) => operation_types[key] === res.data.Data[0].BulkOperationRequestType);
        setOperation(operationType);
        const columnSetToArray = Array.from(columnsSet);
        const cols = handleTableColumns(columnSetToArray);
        setColumns(cols);
        const addedID = res.data.Data.map((obj) => ({ ...obj, id: obj.TransactionId }));
        setProcessId(addedID[0]?.BulkProcessId);
        if (addedID[0]?.ProductId !== '0') {
          setRows(addedID);
        }
        setTotalRows(res.data.Pagination.TotalCount);
      })
      .catch((err) => console.error(err))
      .finally(() => setLoading(false));
  }, [id, paginationModel]);

  return (
    <Modal desktopModalWidth="60%" containerWidth="xl" open={open} onClose={() => setIsOpen(false)}>
      <div className="relative max-h-full">
        <section className="flex items-end justify-between w-full">
          <div>
            <div className="flex items-center justify-start gap-4 mb-2">
              <Typography variant="subtitle1" fontFamily="GothamMedium">No Change:</Typography>
              <Typography variant="subtitle1">
                {parentData.NoChange}
              </Typography>
              <Typography variant="subtitle1" fontFamily="GothamMedium">Inserted:</Typography>
              <Typography variant="subtitle1">
                {parentData.Inserted}
              </Typography>
              <Typography variant="subtitle1" fontFamily="GothamMedium">Updated:</Typography>
              <Typography variant="subtitle1">
                {parentData.Updated}
              </Typography>
              <Typography variant="subtitle1" fontFamily="GothamMedium">Failed:</Typography>
              <Typography variant="subtitle1">
                {parentData.Failed}
              </Typography>
              <Typography variant="subtitle1" fontFamily="GothamMedium">Deleted:</Typography>
              <Typography variant="subtitle1">
                {parentData.Deleted}
              </Typography>
              <Typography variant="subtitle1" fontFamily="GothamMedium">Total:</Typography>
              <Typography variant="subtitle1">
                {parentData.Total}
              </Typography>
            </div>
            {parentData.BulkOperationRequestType !== 34 ? (
              <div className="flex items-center justify-start gap-4 mb-2">
                <Typography variant="subtitle1" fontFamily="GothamMedium">Operation:</Typography>
                <Typography variant="subtitle1">
                  {operation}
                </Typography>
              </div>
            ) : (
              <>
                <div className="flex items-center justify-start gap-4 mb-2">
                  <Typography variant="subtitle1" fontFamily="GothamMedium">Location:</Typography>
                  <Typography variant="subtitle1">
                    {`${parsedHeaderInfo[0].SiteName} / ${parsedHeaderInfo[0].SourceName} / ${parsedHeaderInfo[0].SourceLocation}`}
                  </Typography>
                </div>
                <div className="flex items-center justify-start gap-4 mb-2">
                  <Typography variant="subtitle1" fontFamily="GothamMedium">Threshold:</Typography>
                  <Typography variant="subtitle1">
                    {`${parsedHeaderInfo[0].CurrentMarginThreshold} -> ${parsedHeaderInfo[0].NewMarginThreshold}`}
                  </Typography>
                </div>
                <div className="flex items-center justify-start gap-4 mb-2">
                  <Typography variant="subtitle1" fontFamily="GothamMedium">Update Existing:</Typography>
                  <Typography variant="subtitle1">
                    {parentData.Updated > 0 ? 'Yes' : 'No'}
                  </Typography>
                </div>
              </>
            )}
            <div className="flex items-center justify-start gap-4 mb-2">
              <Typography variant="subtitle1" fontFamily="GothamMedium">Bulk Process ID:</Typography>
              <Typography variant="subtitle1">{processId}</Typography>
            </div>
          </div>
          <div>
            <div className="flex items-center justify-start gap-4 mb-2">
              <Typography variant="subtitle1" fontFamily="GothamMedium">Total Transaction Time:</Typography>
              <Typography variant="subtitle1">
                {msToTime(new Date(parentData.LastTransactionProcessDate) - new Date(parentData.InsertDate))}
              </Typography>
            </div>
            <div className="flex items-center justify-start gap-4 mb-2">
              <Typography variant="subtitle1" fontFamily="GothamMedium">Processing Time:</Typography>
              <Typography variant="subtitle1">
                {msToTime(new Date(parentData.LastTransactionProcessDate) - new Date(parentData.ProcessStartDate))}
              </Typography>
            </div>
          </div>
        </section>
        <Box sx={{ height: 'calc(100vh - 300px)', width: '100%' }}>
          {rows.length > 0 ? (
            <DataGrid
              rows={rows}
              columns={columns}
              loading={loading}
              paginationModel={paginationModel}
              onPaginationModelChange={setPaginationModel}
              pageSizeOptions={[25, 50, 100]}
              components={{ Toolbar: (params) => CustomToolbar(params, rows[0]?.BulkProcessId, parentData.SiteId) }}
              rowCount={rowCountState}
              paginationMode="server"
              disableRowSelectionOnClick
              getRowHeight={() => 'auto'}
              getEstimatedRowHeight={() => 200}
              sx={{ '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '10px' } }}
              density="compact"
            />
          ) : (
            <Stack height="100%" alignItems="center" justifyContent="center">
              <Typography variant="body1" className="font-gothammedium">
                Existing products not updated
              </Typography>
            </Stack>
          )}
        </Box>
      </div>
    </Modal>
  );
}
